@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --blue: rgb(26, 115, 232);
    --green:hsl(126.15deg 16.05% 52.35%);
    --darkgreen: #303133;
    --darkblue: #263F54;
    --twilight:#635d6f;
    --midnight:#16031F;
    --black: #363636;
    --text-gray: #777777;
    --pure-black: hsl(210, 9%, 9%);
    --blue-alpha: 26, 115, 232;
    --gray: #dfdfdf;
    --backdrop-filter: saturate(180%) blur(5px);
}

/* @font-face {
    font-family: 'iroid';
    src: url('../fonts/iroid.eot');
    src: url('../fonts/iroid.eot#iefix') format('embedded-opentype'),
        url('../fonts/iroid.woff') format('woff'),
        url('../fonts/iroid.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} */

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'iroid' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-down:before {
    content: "\e90e";
}

.icon-chevron-left:before {
    content: "\e90f";
}

.icon-chevron-right:before {
    content: "\e910";
}

.icon-chevron-up:before {
    content: "\e911";
}

.icon-clock:before {
    content: "\e912";
}

.icon-eye:before {
    content: "\e913";
}

.icon-globe:before {
    content: "\e919";
}

.icon-phone:before {
    content: "\e914";
}

.icon-plus:before {
    content: "\e915";
}

.icon-user:before {
    content: "\e916";
}

.icon-x:before {
    content: "\e917";
}

.icon-x-circle:before {
    content: "\e918";
}

.icon-facebook1:before {
    content: "\e900";
}

.icon-github:before {
    content: "\e901";
}

.icon-gitlab:before {
    content: "\e902";
}

.icon-instagram1:before {
    content: "\e903";
}

.icon-linkedin1:before {
    content: "\e904";
}

.icon-mail:before {
    content: "\e905";
}

.icon-map-pin:before {
    content: "\e906";
}

.icon-phone-call:before {
    content: "\e907";
}

.icon-phone-incoming:before {
    content: "\e908";
}

.icon-rss:before {
    content: "\e909";
}

.icon-share-2:before {
    content: "\e90a";
}

.icon-twitch:before {
    content: "\e90b";
}

.icon-twitter1:before {
    content: "\e90c";
}

.icon-zap:before {
    content: "\e90d";
}

.icon-aperture:before {
    content: "\e91a";
}

.icon-archive:before {
    content: "\e91b";
}

.icon-award:before {
    content: "\e91c";
}

.icon-bookmark:before {
    content: "\e91d";
}

.icon-box:before {
    content: "\e91e";
}

.icon-check-circle:before {
    content: "\e91f";
}

.icon-check-square:before {
    content: "\e920";
}

.icon-codepen:before {
    content: "\e921";
}

.icon-layers:before {
    content: "\e922";
}

.icon-user-check:before {
    content: "\e923";
}

.icon-user-plus:before {
    content: "\e924";
}

.icon-users:before {
    content: "\e925";
}

.icon-twitter:before {
    content: "\f099";
}

.icon-facebook:before {
    content: "\f09a";
}

.icon-facebook-f:before {
    content: "\f09a";
}

.icon-pinterest:before {
    content: "\f0d2";
}

.icon-linkedin:before {
    content: "\f0e1";
}

.icon-instagram:before {
    content: "\f16d";
}

/* TEXT GRADIENT */

.text-green-gradient {
    background: linear-gradient(to right, var(--green) 0%, hsl(126.15deg 13.73% 36.7%) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.-z-1 {
    z-index: -1;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.text-theme {
    color: var(--blue);
}

.text-black {
    color: var(--pure-black);
}

.text-gray {
    color: var(--text-gray);
}

.bg-pure-black {
    background-color: var(--pure-black);
}

.text-blue {
    color: var(--blue);
}

.text-green {
    color: var(--darkgreen);
    font-weight: bold;
}

.text-midnight {
    color: var(--twilight);
}

.bg-blue {
    background-color: var(--blue);
}

.bg-green {
    background-color: var(--green);
}

.bg-darkgreen {
    background-color: var(--darkgreen);
}

.section--dark {
    background-color: #1b1e20;
}

.text-secondary {
    color: var(--green);
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.backdrop {
    background-color: rgba(36, 35, 39, 0.4);
    opacity: 0;
    transition: opacity .4s linear;
}

.modal.is-open .backdrop {
    opacity: 1;
    transition: opacity .5s linear;
}

html.menu-open,
html.modal-open {
    overflow: hidden;
}

.fadeIt {
    opacity: 0;
    transition: opacity 0.15s ease;
}

html.ready .fadeIt {
    opacity: 1;
}

/* FLKTY */

.flkty-btn {
    position: relative;
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    text-align: center;
    outline: 0;
    min-width: 3.5rem;
    width: 3.5rem;
    height: 3.5rem;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all cubic-bezier(.645,.045,.355,1) .35s;
}

.flkty-btn:hover {
    transform: scale3d(1.06,1.06,1);
    background-color: rgba(150,144,162,.08);
}

.flkty-btn:active, .flkty-btn:focus {
    background-color: rgba(150,144,162,.08);
    box-shadow: inset 0 0 0 0.3125rem rgba(150,144,162,.15);
}

/* // HOMEPAGE */

[data-services] .carousel-cell {
    background-color: white;
    width: 90%;
    margin: 0 15px 0 0;
}

[data-services] .carousel-cell .service-cell {
    display: block;
}

[data-testimonials] .carousel-cell {
    min-height: 40vh;
}

[data-services] .carousel-cell .home-card {
    border: 1px solid rgba(228,228,228,.8);
    padding: 28px;
    height: 100%;
    transition: border-color .2s cubic-bezier(.455,.03,.515,.955), opacity 0.325s ease-out, transform 0.325s ease-in-out;
    opacity: 0;
    transform: translateX(30px);
}

.services-scroll.is-inview .carousel-cell .home-card {
    transform: none;
    opacity: 1;
}

[data-services] .carousel-cell .home-card:hover {
    border-color: rgba(27,27,27,.5);
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/");
}

.backdrop-filter {
    backdrop-filter: var(--backdrop-filter);
}

/* HEADER SECTION ANIMATION */

.dbl__title-wrapper, .header_desc, .dbl__title-wrapper, .header_desc, .hero_btn {
    opacity:0;
}

.header_desc, .hero_btn {
    transform: translateY(20px);
}

.image-grow-cover {
    background-color: white;
    transition: transform 1.25s cubic-bezier(0.5,0,0,1);
    z-index: 1;
}
  
.image-grow img {
    transform: scale(1.5)
}

.dbl__title-wrapper .dbl__title .line {
    transform: translateY(103%);
    padding-bottom: 5px;
}

.is-anim-ready .header_desc, .is-anim-ready .hero_btn {
    opacity:1;
    transition: all .75s cubic-bezier(0.5,0,0,1);
    transform: none;
}

.is-anim-ready .header_desc {
    transition-delay: 0.35s
}

.is-anim-ready .hero_btn {
    transition-delay: 0.45s
}

.is-anim-ready .dbl__title-wrapper {
    opacity:1;
}

.is-anim-ready .dbl__title-wrapper .dbl__title .line {
    animation: translate-up-0-masked .75s cubic-bezier(0.5,0,0,1) forwards;
}

.is-anim-ready .inner-header-section .image-grow-cover {
    transform: translateX(-103%);
}
  
.is-anim-ready .inner-header-section .image-grow img {
    transform: none;
    transition: transform 1.25s cubic-bezier(0.5,0,0,1)
}

@keyframes translate-up-0-masked {
    0% {
      transform: translateY(103%);
    }
  
    100% {
        transform: translateY(0);
    }
}

.container-text h2 {
    color: var(--darkgreen);
}

.container-text p {
    color: var(--twilight);
}

.container-text h2:before {
    content: '';
    height: 3px;
    width: 30px;
    border-radius: 2px;
    background-color: #2884F6;
    position: absolute;
    top: 50%;
    right: calc(100% + 15px);
    transform: scaleX(0);
    transition: transform 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
}

.container-text.in-view h2:before {
    transform: scaleX(1);
}

.carousel-cell.is-active .will-scroll {
    animation-duration: 5s;
    animation-name: scrollCoverBrowser;
    animation-timing-function: cubic-bezier(1.000, 0.005, 0.305, 1.000);
    animation-direction: alternate;
    animation-fill-mode: forwards;
}

@keyframes scrollCoverBrowser {
    10% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(-40%);
    }

    100% {
        transform: translateY(calc(-100% + 21vw));
    }
}

/* LINK STYLES */

.ir-button.ir-text-underline::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: background-color .25s ease-in-out,width .36s cubic-bezier(.51,.5,.07,.99);
    backface-visibility: hidden;
}

.ir-text-button.ir-text-underline::after {
    background-color: #bae6c4;
    height: 7px;
}

.ir-ui-button:hover .ir-text-underline::after {
    width: 0;
}

/* // HEADING STYLES */

.inner-section-wrapper {
    background-color: white;
    border-radius: 25px 25px 0px 0px;
}

.inner-hero-heading {
    color: rgb(35 35 35);
    letter-spacing: -4px;
    font-weight: 600;
    font-size: 47px;
    line-height: 52px;
    max-width: 1000px;
}

.inner-hero-heading.light {
    color: rgb(255, 245, 245);
}

.inner-sub-heading {
    color: rgb(35 35 35);
    letter-spacing: -2px;
    font-weight: 600;
    font-size: 40px;
    line-height: 44px;
    max-width: 620px;
}

.inner-sub-sub-heading {
    letter-spacing: -1px;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    max-width: 1000px;
}

.inner-sub-sub-heading.dark {
    color: rgb(35 35 35);
}

.inner-sub-sub-heading.light {
    color: rgb(244, 244, 244);
}

/* BUTTON */

.u-button {
    transition: all .3s ease!important;
}

.u-button:hover {
    letter-spacing: 2.5px;
}

.button-theme {
    background-color: rgba(255, 255, 255, 0.5);
}

.svg-theme {
    fill: var(--blue);
}

.menu-trigger {
    z-index: 999;
    width: 50px;
    height: 50px;
    transition: background-color .4s, color .4s, border-color .4s, transform .4s;
}

.menu-trigger:hover .menu-trigger__shape {
    stroke-dashoffset: 0;
}

.logo.svg-theme {
    transition: 1500ms ease-in-out;
}

.logo.svg-theme {
    fill: #7D7DDC;
}

.Projects .Projects-Grid-Item {
    display: inline;
}

.Projects .Projects-Grid-Item figure.module-image {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    display: block;
    height: 595px;
    position: relative;
    transition: all .5s cubic-bezier(.67, 0, .285, 1);
}

.GmKuI {
    display: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(96, 62, 255, 0.9);
    top: 0;
}

.Projects-Showcase .Projects-Showcase-Item {
    width: 20%;
    padding: 10px;
    display: inline;
}

/* ABOUT */

.about_copy P {
    margin-bottom: 15px;
    font-size: 18px;
}

.locations-map__info-value {
    font-size: 54px;
}

.locations-map {
    width: 340px;
}

.locations-map__pins {
    position: relative;
    z-index: 10;
}

.locations-map__pin {
    height: 10px;
    width: 10px;
    position: absolute;
    display: flex;
    top: 149px;
    left: 730px;
}

.locations-map__info--right {
    margin: 0;
    left: auto;
    right: 35px;
    top: -12px;
}

.locations-map__info {
    display: flex;
    flex-direction: column;
    top: 0;
    z-index: 5;
}

.locations-map__info .widget-blur {
    width: 45%;
    z-index: -1;
    filter: blur(25px) hue-rotate(215deg);
    opacity: 0.25;
    right: 15%;
    top: 10px;
}

.svg-illustration {
    max-width: 100%;
    position: relative;
    z-index: 3;
    overflow: visible !important;
}

.svg-illustration--map .fill-gray-darker {
    fill: #dfe3ed;
}

.pulse-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 555;
    transition: transform 0.15s ease;
}

.pulse-box:hover {
    transform: scale(1.2);
}

.pulse-css {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: radial-gradient(var(--green) 70%, hsl(126.15deg 13.73% 36.7%) 70%);
    position: relative;
}

.pulse-css:after,
.pulse-css:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--green);
    position: absolute;
    inset: 0;
    margin: auto;
    transform: scale(.5);
    transform-origin: center center;
    animation: pulse-me 3s linear infinite;
    pointer-events: none;
}

.map-section {
    min-height: auto;
}

.map-section.is-inview .map-marker {
    opacity: 1;
    transform: scale(1)
}

.map-marker {
    position: absolute;
    opacity: 0;
    transform: scale(2.2);
    transition: transform .15s ease-in, opacity .25s ease-in
}

.map-marker:nth-child(1) {
    top: 50px;
    left: 5rem
}

.map-marker:nth-child(2) {
    top: 80px;
    left: 5.5rem;
    transition-delay: .5s
}

.map-marker:nth-child(3) {
    top: 170px;
    left: 9.5rem;
    transition-delay: .75s
}

.map-marker:nth-child(4) {
    top: 65px;
    left: 16.25rem;
    transition-delay: .9s
}

.map-marker:nth-child(5) {
    top: 80px;
    left: 17rem;
    transition-delay: 1s
}

.map-marker:nth-child(6) {
    top: 50px;
    left: 18rem;
    transition-delay: 1.15s
}

.map-marker:nth-child(7) {
    top: 140px;
    left: 16rem;
    transition-delay: 1.25s
}

.map-marker:nth-child(8) {
    top: 180px;
    left: 18.5rem;
    transition-delay: 1.35s
}

.map-marker:nth-child(9) {
    top: 85px;
    left: 18rem;
    transition-delay: 1.45s
}

.map-marker:nth-child(10) {
    top: 90px;
    left: 19rem;
    transition-delay: 1.65s
}

.map-marker:nth-child(11) {
    top: 118px;
    left: 18.5rem;
    transition-delay: 1.75s
}

.map-marker:nth-child(12) {
    top: 115px;
    left: 19.5rem;
    transition-delay: 1.95s
}

.map-marker:nth-child(13) {
    top: 130px;
    left: 19.5rem;
    transition-delay: 1.95s
}

.map-marker:nth-child(14) {
    top: 110px;
    left: 20.5rem;
    transition-delay: 1.95s
}

.map-marker:nth-child(15) {
    top: 125px;
    left: 21.9rem;
    transition-delay: 2.15s
}

.map-marker:nth-child(16) {
    top: 100px;
    left: 24rem;
    transition-delay: 2.35s
}

.map-marker:nth-child(17) {
    top: 115px;
    left: 23.75rem;
    transition-delay: 2.45s
}

.map-marker:nth-child(18) {
    top: 145px;
    left: 24.5rem;
    transition-delay: 2.5s
}

.map-marker:nth-child(19) {
    top: 85px;
    left: 27.75rem;
    transition-delay: 2.75s
}

.map-marker:nth-child(20) {
    top: 95px;
    left: 31.2rem;
    transition-delay: 2.75s
}

.map-marker:nth-child(21) {
    top: 166px;
    left: 28.5rem;
    transition-delay: 2.75s
}

.map-marker:nth-child(22) {
    top: 210px;
    left: 30.2rem;
    transition-delay: 2.75s
}

.pulse {
    width: 15px;
    height: 15px;
    border: 4px solid coral;
    border-radius: 30px;
    background-color: #fff;
    z-index: 10;
    position: absolute;
}

.map-marker .dot {
    border: 10px solid coral;
    background: 0 0;
    border-radius: 60px;
    height: 50px;
    width: 50px;
    animation: pulse 3s ease-out;
    animation-iteration-count: infinite;
    position: absolute;
    top: -18px;
    left: -18px;
    z-index: 1;
    opacity: 0;
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0
    }

    25% {
        -webkit-transform: scale(0);
        opacity: .1
    }

    50% {
        -webkit-transform: scale(.1);
        opacity: .3
    }

    75% {
        -webkit-transform: scale(.5);
        opacity: .5
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0
    }
}

.portfolio-pr-el .container-el .el {
    margin-bottom: 70px;
}

.portfolio-pr-el .container-el .el .illu {
    width: 100%;
}

.portfolio-pr-el .container-el .el:nth-child(odd) .illu {
    left: 0px;
    transform: rotateY(180deg);
}

.portfolio-pr-el .container-el .el:nth-child(even) .illu {
    right: 0px;
}

.portfolio-pr-el .container-el .el .illu .bg {
    transform: scale(1.25);
    background-repeat: no-repeat;
    transition: transform 750ms cubic-bezier(0.215, 0.610, 0.355, 1.000), opacity 750ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
    opacity: 0;
}

.portfolio-pr-el .container-el .el.is-inview .illu .bg {
    display: block;
}

.portfolio-pr-el .container-el .el.is-active .illu .bg {
    transform: scale(1);
    opacity: 1;
}

.portfolio-pr-el .container-el .el .title {
    text-align: left;
    padding-left: calc(15% + 5px);
}

.portfolio-pr-el .container-el .el .title {
    z-index: 1;
}

.portfolio-pr-el .container-el .el .wrapper .container-img {
    width: 400px;
    height: 272px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    opacity: 1;
    transition: opacity 1000ms cubic-bezier(0.694, 0, 0.335, 1);
}

.portfolio-pr-el .container-el .el.reach .wrapper .container-img {
    opacity: 1;
    transform: translateY(0px);
}

.portfolio-pr-el .container-el .el .wrapper .container-img img.desktop {
    filter: drop-shadow(0px 14px 28px rgba(0, 0, 0, 0.3));
}

.portfolio-pr-el .container-el .el .wrapper .container-text {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 335px;
    text-align: left;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 700ms cubic-bezier(0.694, 0, 0.335, 1), -webkit-transform 700ms cubic-bezier(0.694, 0, 0.335, 1);
    transition: opacity 700ms cubic-bezier(0.694, 0, 0.335, 1), transform 700ms cubic-bezier(0.694, 0, 0.335, 1);
    transition: opacity 700ms cubic-bezier(0.694, 0, 0.335, 1), transform 700ms cubic-bezier(0.694, 0, 0.335, 1), -webkit-transform 700ms cubic-bezier(0.694, 0, 0.335, 1);
}

.portfolio-pr-el .container-el .el.is-inview .wrapper .container-text {
    opacity: 1;
    transform: none;
}

.portfolio-pr-el .container-el .el .title h2 {
    display: inline-block;
    color: #363F54;
    font-size: 26px;
    line-height: 30px;
    position: relative;
    transform: translateY(30px);
    transition: all 0.75s ease;
    opacity: 0;
    font-weight: 600;
}

.portfolio-pr-el .container-el .el.reach .title h2 {
    opacity: 1;
    transform: translateY(0px);
}

.portfolio-pr-el .container-el .el .title h2:before {
    right: calc(100% + 18px);
}

.portfolio-pr-el .container-el .el.reach .title h2:before {
    transform: scaleX(1);
}

.portfolio-pr-el .container-el .el .title h2:before {
    content: '';
    height: 3px;
    width: 30px;
    border-radius: 2px;
    background-color: #363F54;
    position: absolute;
    top: 23px;
    transform: scaleX(0);
    transition: transform 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
}

@keyframes anime {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.The-Process {
    background-repeat: no-repeat;
    /* background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5); */
    background: linear-gradient(45deg, var(--darkgreen), var(--green));
    background-size: 200%;
    animation: anime 16s linear infinite;
}

.The-Process .Framework-Wrapper a {
    margin: 0.85rem;
}

.The-Process .u-button {
    background-color: rgba(255, 255, 255, 0.2);
}

@media(min-width: 1299px) {

    [data-services] .carousel-cell {
        width: 70%;
    }
}

h2.section-head {
    position: relative;
    text-align: center;
    color: var(--black);
    letter-spacing: 1px;
    z-index: 1;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
}

.tech-nav-list .nav-link {
    padding: 10px;
    border-radius: 5px;
    transition: all .2s;
    display: block;
}

.blog-posts .blog-entry {
    box-shadow: 0 50px 100px rgb(0 0 0 / 10%), 0 7px 20px rgb(0 0 0 / 5%);
}

.demo-wrapper {
    overflow-x: hidden;
}

.wrapper {
    display: flex;
}

.demo-gallery:not(.last) {
    padding-bottom: 1rem;
}

.demo-text .text {
    font-size: clamp(8rem, 15vw, 14rem);
    line-height: 1;
}

[data-background="dark"] body {
    background-color: #252422;
    transition: background-color 0.3s ease-in-out;
}

.clients-container .client-box {
    padding: 20px;
    border: 1px solid #d2d2d2;
    filter: grayscale(1);
    opacity: 0.575;
    transition: filter 0.25s ease-in-out, opacity 0.3s ease-in-out;
}

.clients-container .client-box:hover {
    filter: grayscale(0);
    opacity: 0.975;
}

/* FOOTER */

.footer {
    color: white;
    background-color: #1b1b1b;
    padding-top: 52px;
    padding-bottom: 50px;
    margin-top: 1rem;
}
.footer-listing {
    list-style: none;
}

.footer-listing li a {
    @apply flex items-center no-underline;
    color: #888;
    font-weight: 500;
    line-height: 1.5;
    transition: color .12s cubic-bezier(.455, .03, .515, .955);
    margin-bottom: 0.25rem;
    font-weight: 500;
}

.footer-listing li a:hover:after {
    width: 100%;
}

.footer-listing li a:after {
    content: "";
    width: 0%;
    border-radius: 100px;
    height: 0.5px;
    background: #616161;
    transition: all 1500ms cubic-bezier(0.000, 0.785, 0.000, 1.000);
    @apply absolute left-0 bottom-1;
}

.is-services-page #viewport {
    background: linear-gradient(180deg, #fff, #f1f4fd 50%) !important;
}

.service-card-line {
    transition: all .2s ease-in;
}

.service-card-line:hover {
    box-shadow: 0 10px 12px #dfdfdf;
    transform: scale(1.05) !important;
}

#menu--container {
    will-change: auto;
    opacity: 0;
    transition: all 0.25s ease;
    z-index: 8890;
    width: 98%;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform 0.5s cubic-bezier(0.45, 0.46, 0, 1.05), opacity 0.225s cubic-bezier(0.41, -0.03, 0.29, 0.84)
}

#menu--container #fly-menu {
    opacity: 0;
    transition: opacity 0.225s cubic-bezier(0.41, -0.03, 0.29, 0.84)
}

#menu--container #fly-menu .container-nav li {
    margin-bottom: 15px;
}

.menu-open #menu--container {
    transform: none;
    opacity:1;
}

.menu-open #menu--container #fly-menu {
    opacity:1;
}

#fly-menu.is-open {
    display: block;
    visibility: visible;
    opacity: 1
}

/* #fly-menu.is-enter-active {
    @apply visible opacity-100;
    @apply transition transition-all duration-300 ease-in-out;
    z-index: 999;
}

#fly-menu.is-exit-active {
    @apply invisible opacity-0;
    @apply transition transition-all duration-300 ease-in-out;
} */

.fly-menu .fly-menu-inner .menu-wrapper {
    z-index: 1;
}

.fly-menu .fly-menu-inner .menu-wrapper .dropdown-divider {
    background-color:rgba(255,255,255,0.3)
}

/* #fly-menu.is-enter-active .word-wrap .word-wrap-inner {
    transform: none;
} */

/* .fly-menu .fly-menu-inner .menu-wrapper .container-nav.menu-left li:nth-child(1) .common-link .word-wrap .word-wrap-inner {
    transition-delay: 0.25s;
}

.fly-menu .fly-menu-inner .menu-wrapper .container-nav.menu-left li:nth-child(2) .common-link .word-wrap .word-wrap-inner {
    transition-delay: 0.35s;
}

.fly-menu .fly-menu-inner .menu-wrapper .container-nav.menu-left li:nth-child(3) .common-link .word-wrap .word-wrap-inner {
    transition-delay: 0.45s;
}

.fly-menu .fly-menu-inner .menu-wrapper .container-nav.menu-left li:nth-child(4) .common-link .word-wrap .word-wrap-inner {
    transition-delay: 0.55s;
}

.fly-menu .fly-menu-inner .menu-wrapper .container-nav.menu-left li:nth-child(5) .common-link .word-wrap .word-wrap-inner {
    transition-delay: 0.65s;
}

.fly-menu .fly-menu-inner .menu-wrapper .container-nav.menu-left li:nth-child(6) .common-link .word-wrap .word-wrap-inner {
    transition-delay: 0.75s;
} */

.fly-menu .fly-menu-inner .menu-wrapper .container-nav li a {
    color: #ffffff;
    text-decoration: none;
    position: relative;
}

.fly-menu .fly-menu-inner .menu-wrapper .container-nav li a svg {
    fill: #ffffff;
}

.fly-menu .fly-menu-inner .menu-wrapper .container-nav li a .link-line {
    transition: opacity .25s ease;
    opacity: 0;
}

.fly-menu .fly-menu-inner .menu-wrapper .container-nav li a:not(.social) .link-line {
    width: 40px;
    margin-left: 32px;
}

.fly-menu .fly-menu-inner .menu-wrapper .container-nav li a .link-line {
    height: 3px;
}

.fly-menu .fly-menu-inner .menu-wrapper .container-nav li a .link-line::before {
    content: "";
    display: block;
    background-color: var(--blue);
    height: 100%;
    width: 100%;
    border-radius: 2px;
    transition: all .45s cubic-bezier(0, .095, 0, 1);
}

.fly-menu .fly-menu-inner .menu-wrapper .container-nav li a:hover .link-line {
    opacity: 1;
}


/* Important styles */

#toggle span:after,
#toggle span:before {
    content: "";
    position: absolute;
    left: 0;
    top: -9px;
}

#toggle span:after {
    top: 9px;
}

#toggle span {
    position: relative;
    display: block;
}

#toggle span,
#toggle span:after,
#toggle span:before {
    width: 100%;
    height: 2px;
    background-color: var(--pure-black);
    border-radius: 2px;
    margin-top: 1px;
}

/* on activation */
#toggle.is-open span {
    background-color: transparent;
}

#toggle.is-open span:before {
    transform: rotate(45deg) translate(5px, 5px);
}

#toggle.is-open span:after {
    transform: rotate(-45deg) translate(7px, -8px);
}

.has-dots {
    content: "";
    background: url(../../public/static/home/images/dots.png) no-repeat;
    width: 70px;
    height: 70px;
    background-size: cover;
}

.ir-grid .image-grid-small {
    height: 100%;
    align-self: end;
}

.ir-grid.reverse .image-grid-small {
    height: 100%;
    align-self: start;
}

.fly-menu .fly-menu-inner {
    transition: background-color 0.3s ease-out;
}

.menu-open .u-button {
    color: white;
}

.fly-menu .fly-menu-inner {
    background-color: #14041e;
    border-radius: 30px 0 0 30px;
}

.el-solutions .fly-menu .fly-menu-inner {
    background-color: white;
}

.el-hire-developer .fly-menu .fly-menu-inner {
    background-color: rebeccapurple;
}

.el-contact .fly-menu .fly-menu-inner {
    background-color: black;
}

#viewport {
    @apply transition transition-all duration-1000 ease-in-out;
}

#modal-overlay {
    background-color: rgba(230, 230, 230, 0.85);
    transition: all 400ms cubic-bezier(.4, 0, .2, 1);
    opacity: 0;
    visibility: hidden;
    z-index: 8888;
}

.menu-open #modal-overlay {
    opacity: 1;
    visibility: visible;
}

.modal {
    opacity: 0;
    z-index: 9999;
    visibility: hidden;
}

.modal.is-open {
    opacity: 1;
    visibility: visible;
    display: flex;
}

.modal.o-form {
    display: none;
    transition: all 800ms cubic-bezier(.4, 0, .2, 1);
}

.modal.o-form.is-open {
    display: flex;
}

.modal-open #viewport {
    transform: translateX(-150px);
}

.modal.modal-side {
    transform: translateX(101%);
    transition: transform 0.75s cubic-bezier(0.57, 0.36, 0.06, 0.94);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: 10px 1px 27px 15px hsl(0deg 0% 0% / 20%);
}

.modal .modal-dialog {
    visibility: hidden;
    margin: 1.75rem auto;
}

.modal .modal-offset.modal-dialog {
    margin: 0;
}

.modal.is-open .modal-dialog {
    visibility: visible;
    animation: 400ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s 1 normal forwards running k1;
}

.modal .modal-dialog .modal-content {
    box-shadow: 0 10px 25px 2px rgb(0 0 0 / 10%), 0 10px 80px 5px rgb(0 0 0 / 10%);
    border-radius: 15px;
}

.modal.is-open:not(.modal-side) .modal-dialog .modal-content {
    animation: 400ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s 1 normal forwards running k2;
}

.modal.is-enter-active {
    @apply visible;
    transform: none
}

.modal.is-exit-active {
    @apply invisible;
    background-color: white;
}


.is-dark .logo path {
    fill: rgba(255, 255, 255, 0.8);
}

.is-dark .menu-r-section .header-menu .header-link {
    color: rgba(255, 255, 255, 0.8) !important;
}

.is-dark .fly-menu .fly-menu-inner .menu-wrapper .container-nav li a {
    color: white;
}

.is-light .logo path {
    fill: rgba(31, 31, 36, 0.8);
}

.is-light .menu-r-section .header-menu .header-link {
    color: rgba(31, 31, 36, 0.8) !important;
}

.is-light .fly-menu .fly-menu-inner .menu-wrapper .container-nav li a {
    color: var(--black);
}

@keyframes slide {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 300px 0;
    }
}

ul.submenu li.menu-list-item .navbar-item {
    position: relative;
    width: max-content;
}

ul.menu-call-out .menu-call-item .g-img-container,
ul.menu-call-out .menu-call-item h2 {
    pointer-events: none;
}

ul.menu-call-out .menu-call-item .g-img-container img {
    transition: filter 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: scale(1);
}

ul.menu-call-out .menu-call-item:hover .g-img-container img {
    filter: hue-rotate(223deg);
    transform: scale(1.02);
}

ul.menu-call-out .menu-call-item .menu-call-title span:nth-child(2)::after {
    transition-delay: 0.2s;
}

/* / TABS */

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.container-illu .container-device .device.desktop .head {
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 21px;
    background-color: #FFF;
    border-radius: 5px 5px 0px 0px;
}

.container-illu .container-device .device.desktop .head .ptn:nth-child(1) {
    left: 6px;
}

.container-illu .container-device .device.desktop .head .ptn:nth-child(2) {
    left: 16px;
}

.container-illu .container-device .device.desktop .head .ptn:nth-child(3) {
    left: 26px;
}

.container-illu .container-device .device.desktop .head .ptn {
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 3px;
    top: 50%;
    background-color: #232426;
    transform: translateY(-50%);
    opacity: 0.2;
}

.container-illu .container-device .container-screen img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}


.inner-header-section.sub-header {
    background: url(../../public/static/home/images/banner/h1-slider-1-9.png);
    background-size: cover;
}

/* MODAL */

.modal.modal-side .modal-content {
    padding: 1rem;
    padding-top: 2.5rem;
}

.modal.modal-side .modal-content button[type="button"] {
    right: 1rem;
    top: 1rem;
}

.modal.modal-side .modal-content button[type="button"]:hover {
    background-color: var(--darkgreen);
}

.modal.modal-side .modal-content .modal-body ul.c-options li {
    margin-right: 1.5rem;
    margin-bottom: .5rem;
}

@keyframes button-anim {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes pulse-me {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    50% {
        opacity: 0.1;
    }

    70% {
        opacity: 0.09;
    }

    100% {
        transform: scale(5);
        opacity: 0;
    }
}

.form-button::after {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    position: absolute;
    left: calc(50% - 0.75em);
    top: calc(50% - 0.75em);
    border: 0.15em solid transparent;
    border-right-color: white;
    border-radius: 50%;
    animation: button-anim 0.7s linear infinite;
    opacity: 0;
}

.form-button.loading {
    color: transparent;
}

.form-button.success {
    background-color: #57c357;
}

.form-button.loading::after {
    opacity: 1;
}

.Call-Out-Container .call-out-images .image-grid {
    grid-template-columns: 1fr 90px;
}

.cd-words-wrapper {
    display: inline-block;
    position: relative;
}

.cd-words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: normal;
    left: 0;
    top: 0;
    font-weight: bold;
}

.cd-words-wrapper b.is-visible {
    position: relative;
}

.no-js .cd-words-wrapper b {
    opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
    opacity: 1;
}

/* -------------------------------- 
xpush 
-------------------------------- */
.cd-headline.push b {
    opacity: 0;
  }
  .cd-headline.push b.is-visible {
    opacity: 1;
    animation: push-in 0.6s;
  }
  .cd-headline.push b.is-hidden {
    animation: push-out 0.6s;
  }
  
  @keyframes push-in {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    60% {
      opacity: 1;
      transform: translateX(10%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes push-out {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    60% {
      opacity: 0;
      transform: translateX(110%);
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
} 


.testimonials.home {
    background-color: #fafafa;
}

.nav-tabs.tech-navbar .nav-item a {
    opacity: 0.55;
    transition: opacity 0.3s ease-in-out
}

.nav-tabs.tech-navbar .nav-item a.active {
    background-color: #2b25bd;
    color: rgb(226 226 226);
    opacity: 1;
}

.nav-tabs.tech-navbar .nav-item a.active span.number {
    border-color: #5da9ff;
}

.nav-tabs.tech-navbar .nav-item a span.number {
    background-color: #14041e;
    color: white;
    font-weight: bold;
    border: 2px solid #544896;
}

.text-editor p {
    margin-bottom: 30px;
}

.text-editor ul {
    margin-bottom: 30px;
    margin-top: 20px;
}

.text-editor ul li {
    list-style: circle;
}

.faq .card .card-header .faq-title {
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 1px;
    color: #3b566e;
    transition: all .3s ease 0s;
    padding: 18px 10px 15px 25px;
    position: relative;
}

.faq .card .card-header .faq-title .badge {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 100px;
    text-align: center;
    background: linear-gradient(45deg, var(--darkgreen) 0%, var(--green) 45%);
    color: #fff;
    font-size: 12px;
    margin-left: -50px;
    position: absolute;
    top: 18px;
}

ul.faq .collapse {
    padding: 20px 30px 20px 20px;
    overflow: hidden;
    display: none;
}

.alert {
    padding: 0.5rem 0 0.5rem 1rem;
    position: relative;
    border: 1px solid var(--bs-alert-border-color);
    background-color: var(--bs-alert-bg);
}

.alert.alert-success {
    --bs-alert-border-color: #b6effb;
    --bs-alert-bg: var(--blue);
    color: white;
}

  .bottom-bar-container {
    border-radius: 999px;
    overflow: hidden;
    opacity: 0;
    transform: translateY(20px);
  }

  .bottom-bar-container h2 {
    opacity: 0;
    transform: translateX(-20px);
    transition: all 0.5s cubic-bezier(0.5, 0.14, 0.19, 0.82);
  }

  .bottom-bar-container button {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s cubic-bezier(0.5, 0.14, 0.19, 0.82);
  }

  .bottom-bar-container.in-view {
    opacity: 1;
    transform: none;
  }

  .bottom-bar-container .bottom-bar-inner::before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 999px;
    background-color: rgb(188 188 188 / 50%);
    backdrop-filter: saturate(50%) blur(25px);
    z-index: -1;
    transform: scaleX(0);
    transition: all 0.65s cubic-bezier(0.86, 0.13, 0, 0.93);
  }

  .bottom-bar-container.in-view .bottom-bar-inner::before {
    transform: scaleX(1);
  }

  .bottom-bar-container.in-view button a:hover {
    box-shadow: 0 0 0 2px #c5c5c5;
  }

  .bottom-bar-container.in-view button a:focus {
    box-shadow: 0 0 0 3px #d5b57a;
    transform: scale(0.96);
  }

  .bottom-bar-container.in-view h2, .bottom-bar-container.in-view button {
    opacity: 1;
    transform: none;
    transition-delay: 0.3s;
  }

  .bottom-bar-container.in-view button:nth-child(1) {
    transition-delay: 0.4s;
  }

  .bottom-bar-container.in-view button:nth-child(2) {
    transition-delay: 0.5s;
  }

.social-media-pop svg {
    fill: white;
}